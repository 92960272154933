exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-category-tsx": () => import("./../../../src/pages/category.tsx" /* webpackChunkName: "component---src-pages-category-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-page-tsx": () => import("./../../../src/pages/page.tsx" /* webpackChunkName: "component---src-pages-page-tsx" */),
  "component---src-pages-project-tsx": () => import("./../../../src/pages/project.tsx" /* webpackChunkName: "component---src-pages-project-tsx" */),
  "component---src-pages-publish-tsx": () => import("./../../../src/pages/publish.tsx" /* webpackChunkName: "component---src-pages-publish-tsx" */),
  "component---src-pages-version-tsx": () => import("./../../../src/pages/version.tsx" /* webpackChunkName: "component---src-pages-version-tsx" */)
}

